.Button.default.result{
  top:      120px;
}

.Label.default{
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  position:   relative;
  display:    inline-flex;
  align-items: center;
  padding:    none;
  font-size:  12px;
  word-wrap: break-word;
}

.Result-Block-Icon.default{
  top:        10px;
  opacity:    0.8;
}

.Result-Block-Text.default{
  top:        30px;
}

.Label-Title.default{
  color:  #fff
}

.Label-Text.default{
  word-break: break-all;
  word-wrap: break-word;
}

.Label-Title.default.top.small{
  font-size:  12px;
  font-weight: 400;
}


@media screen and ( max-width: 480px) {

  .Content-Form.default.white.result{
    top:      8px !important;
    width:    calc(100% - 8px) !important;
    left:     8px !important;
    height:   192px !important;
  }

  .Content-Form.default.white.result::after{
    width:   calc(100% - 8px) !important; 
    height:  auto !important;
    left:    -8px !important;
    top:     -8px !important;
    height:  190px !important;
  }
}