
.Page.default{
  background:   #fff;
}

.Content.default{
  position:     relative; 
  width:        437px;
  display:      block;
  margin:       0 auto;
  z-index:      1;
}

.Content-Form.default{
  width: 437px;
  height: 124px;
  margin: 7px 0 33px;
  /* padding: 45px 16px 44px 225px; */
  object-fit: contain;
  border-radius: 16px;
  box-shadow: 0 22px 74px 0 rgba(0, 0, 0, 0.13);
  background-color: #64b95f;
  position:   relative;
  top:        40px;
}

.Content-Form.default.top{
  padding:    16px; 
}

.Content-Form.default.white{
  box-sizing: border-box;
  padding:    32px;
  display:  block;
  position: relative;
  width: 420px;
  height: 242px;
  margin: 46px 0 22px 17px;
  border-radius: 16px;
  box-shadow: 0 22px 74px 0 rgba(0, 0, 0, 0.13);
  background-image: linear-gradient(to right,rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0)), linear-gradient(to left, rgba(226, 233, 226, 1), rgba(226, 233, 226, 1));
}

.Content-Form.default.white::after{
  content:    "";
  position:   absolute;
  display:    block;
  width:      400px;
  height:     250px;
  z-index:    -1;  
  background-color: rgba(139, 209, 134, 0.2);
  border-radius: 16px;
  left:       -17px;
  top:        -17px;
}

.Button.default{
  text-align: center;
  justify-content: center;
  display: inline-block;
  border-radius: 10px;
  background-color: transparent;
  font-size: 20px;
  font-weight: bold;
  font-family: FuturaPT-Book,Arial,sans-serif;
  min-width: 185px;
  padding: 10px;
  width: 100px;
  position:   relative;
  color: #fff;
  top:    80px;
  cursor: pointer;
  transition: .3s ease-in;
  background: #64b95f;
  left: calc( 50% - 100px );
}


.Button.default.result{
  top:      220px;
}

.Text.center.bg.default{
  background: #306;
  color: #fff;
  line-height: 1.5;
  font-size: 14px;
  text-align:   center;
  margin-bottom:  20px;
}

.Label.default{
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  width:      100%;
  position:   relative;
  display:    inline-flex;
  align-items: center;
  padding:    none;
  font-size:  12px;
}

.Label-Title.default{
  color:  #fff
}

.Label-Title.default.top.small{
  font-size:  12px;
  font-weight: 400;
}

.Label.default.mid{
  margin:  15px 0;
}

.Label-Title.default.mid{
  font-size:    18px;
  font-weight:  bold;
  width:        205px;
}

.Label-Text.default.right.big{
  display: inline-block;
  top:        10px;
  margin-left:5px;
  font-size:  30px;
  font-weight: bold;
  position:   relative;
  color:     #fff;
  top:        0px;
  display:    inline-block;
  text-align: right;
  width: 220px;
}

.Label-Text.right.big.currency.default{
  margin-left:10px;
  font-size:  14px;
  font-weight:400;
  position:   relative;
  color:     #fff;
  font-size:  30px;
  font-weight: bold;
  width: auto;
  min-width: 67px;
  /* position:   absolute; */
}


.Label-Text.default{
  margin-left: 5px;
  font-size: 12px;
  font-weight: 400;
  color: #fff;
  display:    inline-block;
  
}

.TextField.default{
  width:      100%;
  position:   relative;
  display:    inline-block;
  background: none;
}

.TextField-Title.default{
  color:     #707070;
  font-weight: bold;
  font-size: 16px;
  /* font-weight: 500; */
}

.Text-Field-Validation-Icon{
  position:   absolute;
  top:        12px;
  right:      0px;
  width:      20px;
  height:     20px;
  cursor:     pointer;
}

.Text-Field-Validation-Icon.ok{
  background: url(./icons/validation_success.png);
  background-size: 20px 20px;
}

.Text-Field-Validation-Icon.error{
  background: url(./icons/validation_error.png);
  opacity:    0.6;
  background-size: 20px 20px;
}

.TextField.card{
  margin-bottom: 85px;
}

.TextField.default.small{
  width:      20%;
  background: none;
  float:      right;
}

.TextField.default.little{
  width:      15%;
  background: none;
  margin-right:  20px;
}

.TextField-Text.default{
  background: none;
  color:      #707070;
  width:      100%;
  border:     none;
  border-bottom: solid 2px #565656;
  padding:    10px 0px 4px;
  font-size:  14pt;
  outline:    none;
  text-decoration:  none;
}

.Text.center.default{
  position:  relative;
  display:    inline-block;
  width: 343px;
  height: 32px;
  bottom:   -60px;
  /* margin: 22px 48px 35px 46px; */
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000000;;
  left:  calc( 50% - 172px );
}

@media screen and ( max-width: 480px) {

  .App,#root,body,html,.Wrapper{
    min-height: 560px !important;
  }

  .Content.default{
    width:    100% !important;
    height:   100%;
    padding:  0 8px;
    box-sizing: border-box;
    overflow:  scroll;
    min-height: 560px !important;
  }
  .Content-Form.default{
    top:      0px !important;
    width:    100% !important;
    height:   auto !important;
    margin:   8px 0px 40px !important;
  }

  .Content-Form.default.white{
    width:    calc(100% - 8px) !important;
    left:     8px !important;
  }

  .Content-Form.default.white::after{
    width:   calc(100% - 8px) !important; 
    height:  auto !important;
    left:    -8px !important;
    top:     -8px !important;
    height:  220px !important;
  }

  .Label-Title.default.mid{
    font-size: 9pt !important;
    width:    240px !important;
  }

  .Label-Text.default.right.big{
    font-size: 18pt !important;
  }

  .Label-Text.default.currency.right.big{
    min-width: 52px !important;
    margin-left:  5px !important;
    top: 0px !important;
  }

  .Content-Form.default.white.result{
    top:      8px !important;
    width:    calc(100% - 8px) !important;
    left:     8px !important;
    height:   192px !important;
  }

  .Result-Block-Text.default{
    font-size:  12pt;
  }

  .Content-Form.default.white.result::after{
    width:   calc(100% - 8px) !important; 
    height:  auto !important;
    left:    -8px !important;
    top:     -8px !important;
    height:  190px !important;
  }

  .Text.center.default{
    top:      0 !important;
    width:    100% !important;
    text-align: center !important;
    left:     0px !important;
  }
  .TextField.default.little{
    width:   25% !important;
  }

  .TextField.default.small{
    width:   25% !important;
  }
  .Button.default{
    top:      25px !important;
  }

  .Button.default.result{
    top:      100px !important;
  }

  .Result-Block-Icon.default{
    width:    64px !important;
    height:   64px !important;
  }

  .error.Result-Block-Icon.default{
    background-size:    64px !important;
  }
  .success.Result-Block-Icon.default{
    background-size:    64px !important;
  }

}

@media screen and ( max-width: 360px) {
  .TextField.card{
    margin-bottom:  50px !important;
  }

  .Content-Form.default.white::after{
    height:  180px !important;
  }
}