* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box; 
}

body {
  background-color: #fff;
}

body,html{
  position: relative;
  width:    100%;
  height:   100%;
  padding:  0px;
  margin:   0px;
}


@font-face {
  font-family: HelveticaNeue;
  src: url(../fonts/HelveticaNeue.woff2) format("woff2"), url(../fonts/HelveticaNeue.woff) format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: FuturaPT-Book;
  src: url(../fonts/FuturaPT-Book.woff2) format("woff2"), url(../fonts/FuturaPT-Book.woff) format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }


.App {
  font-family: HelveticaNeue, Arial, sans-serif;
}

#root,.App,.Wrapper,.Page{
  position: relative;
  display:  block;
  width:    100%;
  height:   100%;
  overflow: hidden;
  min-height:750px;
}

.Header{
  position: relative;
  margin:   0 auto;
  width:    800px;
  height:   70px;
  top:      calc(20% - 65px);
}

.LangSelector{
  cursor:   pointer;
  position: relative;
  display: inline-block;
  float:    right;
  width:    70px;
  top:      20px;
  right:    25px;
  border-bottom:   solid 1px #bbb;
}

.LangSelector-Text{
  position: relative;
  display: inline-block;
  padding:  2px 5px;
  color:    #666;
  font-size:  14pt;
}

.LangSelector-Arrow{
  position: relative;
  display: inline-block;
  width:   25px;
  height:  25px;
  left:    10px;
  top:     5px;
  background: url(../icons/arrow.png) no-repeat;
  background-size:   25px 25px;
}

.Icon{
  margin-left:  25px;
  display:    inline-block;
}

.IconText{
  color:    #fff;
  font-size: 10pt;
}

.CardIcon{
  position: relative;
  left:     25px;
  margin-bottom: 5px;
  width:    50px;
  height:   30px;
  border-radius: 3px;
  border:   solid 2px #eee;
}

.CardStripe{
  width:    100%;
  top:      6px;
  position: relative;
  height:   6px;
  background:  #eee;
}

.Body-Half-Blue{
  position:   absolute;
  display:    block;
  height:     150%;
  width:      100%;
  top:        -50%;
  left:       -50%;
  overflow:   hidden;
  /* #2da3f9 */
  background: linear-gradient(0, #2da3f9 0%,  #1696f4 100%);
  /* background-color: #1696f4; */
  transform: rotate(15deg);
}

.Body-Big-Circle{
  position: relative;
  display:  inline-block;
  border-radius: 700px;
  right:    10px;
  top:      100px;
  left:     67%;
  width:    800px;
  height:   800px;
  background: #49b0fb;
  opacity:  0.4;
}


.Loader-Block{
  /* background:  #ccc; */
  position: relative;
  display:  block;
  margin:   0 auto;
  width:    550px;
  top:      30%;
  box-sizing: border-box;
}


.Result-Block{
  position: relative;
  display:  block;
  margin:   0 auto;
  width:    550px;
  height:   260px;
  background: #fff;
  border-radius: 15px;
  box-shadow:  0 0 6px 2px rgba(0,0,0,0.2);
  top:      30%;
}

.Body-Payment-Block-Corner-Logo.ldr{
  z-index:    2;
  bottom:     21px;
  right:      0px;
}


.Body-Payment-Block-Corner-Logo.rslt{
  z-index:    2;
  bottom:     0px;
  right:      0px;
}

.Loader-Text{
  z-index:    2;
  position:   relative;
  display: inline-block;
  color:  #666;
  font-size:  14pt;
  top:    -65px;
  left:   90px;
  /* top:      calc( 50% + 20px );
  left:     calc( 50% - 170px ); */
  width:    auto;
}

.Body-Payment-Block{
  position:  relative;
  display:   block;
  width:     800px;
  height:    450px;
  background: #fff;
  border-radius: 10px;
  margin:    0 auto;
  box-shadow:  0 0 6px 2px rgba(0,0,0,0.2);
  top:      calc(20% - 70px);
}

.Body-Payment-Block-Corner-Logo{
  position:  absolute;
  display:   block;
  width:     150px;
  height:    130px;
  background: url(../icons/BigCorner.png) no-repeat;
  bottom:    0px;
  right:     0px;
  background-size:  170px 150px;
  background-position-x: -15px;
  background-position-y: -17px;
}

.Body-Big-Logo{
  position:   absolute;  
  width:      400px;
  height:     250px;
  top:        calc( 20% + 323px );
  right:      calc( 50% - 400px - 250px );
  background: url(../icons/BigLogo.png) no-repeat;
}

.Body-Big-Logo.ldr{
  top:        calc( 30% + 130px );
  right:      calc( 50% - 400px - 125px );
}

.Body-Big-Logo.rslt{
  top:        calc( 22% + 200px );
  right:      calc( 50% - 400px - 125px );
}

.error.Result-Block-Icon{
  position:   relative;
  width:      80px;
  height:     80px;
  background: url(../icons/error_result.png);
  background-size: 80px;
  margin:     0 auto;
  top:        25px;
  opacity:    0.7;
}

.success.Result-Block-Icon{
  position:   relative;
  width:      80px;
  height:     80px;
  background: url(../icons/success_result.png);
  background-size: 80px;
  margin:     0 auto;
  top:        25px;
  opacity:    0.7;
}

.Result-Block-Text{
  position: relative;
  display:   inline-block;
  margin:    0 auto;
  font-size:  14pt;
  width:      100%;
  text-align: center;
  top:        50px;
  color:      #666;
}

.Body-Payment-Block-Wrapper{
  position:   relative;
  display:    block;
  box-sizing: border-box;
  width:      100%;
  height:     100%;
  padding:    30px 50px;
  box-sizing:border-box;
}

.Body-Payment-Block-Left{
  box-sizing:border-box;
  position: relative;
  float:    left;
  display:  block;
  width:    50%;
  height:   100%;
  top:      25px;
}

.Body-Payment-Block-Right{
  position: relative;
  float:    left;
  display:  block;
  height:   100%;
  width:    50%;
  box-shadow: -6px 0 5px -6px rgba(0,0,0,0.3);
  top:      25px;
  padding-left:  45px;
}

.Label{
  font-family:  HelveticaNeue, Arial, sans-serif;
  font-size:    16pt;
  color:        #666;
  /* margin-bottom:  35px; */
}

.Label.right{
  /* margin-bottom:  100px; */
}

.Text-Field-Wrapper{
  position: relative;
  width:    80%;
  margin-bottom:  30px;
  height:    45px;
}

.Text-Field-Wrapper.cvv{
  float:    right;
  right:    20%;
  position: relative;
  width:    25%;
  margin-bottom:  30px;
  height:    45px;
}

.Text-Field-Wrapper.exp_m{
  float:    left;
  position: relative;
  width:    15%;
  margin-bottom:  30px;
  height:    45px;
  text-align:   center;
}

.Text-Field-Validation-Icon{
  position:   absolute;
  top:        5px;
  right:      -25px;
  width:      20px;
  height:     20px;
  cursor:     pointer;
}

.Text-Field-Validation-Icon.ok{
  background: url(../icons/validation_success.png);
  background-size: 20px 20px;
}

.Text-Field-Validation-Icon.error{
  background: url(../icons/validation_error.png);
  background-size: 20px 20px;
}

.Text-Field-Validation-Icon.slots_city{
  display: none;
}

.BluePipe{
  position: absolute;
  display:  inline-block;
  width:    2px;
  top:      138px;
  height:   30px;
  left:       64px;
  background: #279ff8;
  transform:  rotate(20deg);
}

.Text-Field-Wrapper.exp_y{
  text-align:   center;
  float:    left;
  position: relative;
  width:    15%;
  margin-left:    20px;
  margin-bottom:  30px;
  height:    45px;
}

.Text-Field-Wrapper.exp_y > .TextField{
  text-align:   center;
}

.Text-Field-Wrapper.exp_m > .TextField{
  text-align:   center;
}

.Text-Field-Title{
  font-size:  10pt;
  color:      #666;
  position:   absolute;
  top:        7px;
  left:       5px;
  transition: ease-in-out top 0.2s, ease-in-out left 0.2s;
}

.Text-Field{
  width:      100%;
  border:     none;
  outline:    none;
  text-decoration:  none;
  font-size:  14pt;
  color:    #0e8fef;
  padding:   2px 4px 6px;
  border-bottom:  solid 1px #ccc;
  color: transparent;
  /* border-bottom:  solid 1px #4eaef3; */
}

.onChange.Text-Field{
  color:  #666 !important;
}

.Text-Field-Wrapper::after{
  content:    "";
  display:    inline-block;
  position:   relative;
  width:      0px;
  height:     2px;
  left:       50%;
  top:        -15px;
  background: #4eaef3;
  transition: ease-in-out left 0.2s, ease-in-out width 0.2s;
}

.OnFocus.Text-Field-Wrapper::after{
  left:       0px;
  width:      100%;
}

.OnFocus.Text-Field-Wrapper > .Text-Field{
  color: #aaa;
}

.Text-Field-Button{
  padding: 2px 0px 0px 10px;
  font-size: 16px;
  color:     #fff;
}

.Text-Field-Title.Hide{
  display: none;
}

.OnFocus.Text-Field-Title{
  top:     -15px;
  left:     0px;
  font-size: 9pt;
  color:  #0e8fef;
}

.Text-Field-Title.active{
  top:     -15px;
  left:     0px;
  color:  #0e8fef;
}



.Button{
  position:  relative;
  display:   inline-block;
  width:     80%;
  text-align:  center;
  font-size:    16pt;
  padding:      10px 0;
  color:     #fff;
  background-color: #279ff8;
  cursor:     pointer;
  transition:  ease-in-out box-shadow 0.1s;
}

.Button.Result{
  top:          70px;
  width:        40%;
  left:         45px;
}

.Button.Result.Back{
  top:          85px;
  margin-left:  15px;
  width:        40%;
  left:         150px;
}

.Button:hover{
  box-shadow:   0 0 5px 1px rgba(0,0,0,0.3);
}

.LabelBg{
  position:   relative;
  width:      80%;
  display:    inline-block;
  font-weight:   400;
  margin-bottom:  30px;
  color:      #6f7e8a;
  font-size:   10pt;
  
}

.Label-Currency{
  position:    absolute;
  float:       right;
  color:       #888;
  font-size:   12pt;
  right:       0px;
  top:         6px;
}

.BottomLogo.visa{
  top:         70px;
  display:     inline-block;
  position:    relative;
  width:       75px;
  height:      30px;
  background:  url(../icons/footer-visa-unactive.png) no-repeat;
  background-size:  75px 30px;
}

.Back-Arrow-Icon{
  width: 30px;
  height: 25px;
  background:  url(../icons/left-arrow.png) no-repeat;
  background-size: 30px 25px;
  display:     inline-block;
  position:    relative;

}

.BottomLogo.mc{
  top:         70px;
  position:    relative;
  margin-left: 20px;
  display:     inline-block;
  width:       75px;
  height:      30px;
  background:  url(../icons/footer-MC-active.png) no-repeat;
  background-size:  75px 30px;
}

.BottomLogo.pci{
  top:         70px;
  position:    relative;
  display:     inline-block;
  margin-left: 20px;
  width:       75px;
  height:      30px;
  background:  url(../icons/footer-PCI-unactive.png) no-repeat;
  background-size:  75px 30px;
}



.loader {
  top:        calc(50% - 160px);
  right:      calc(50% - 275px);
  border-radius: 10px;
  text-align: center;
  vertical-align: middle;
  position: relative;
  display: flex;
  float:  right;
  padding: 120px 200px;
  background: rgba( 255,255,255, 1 );
  box-shadow:  0 0 6px 2px rgba(0,0,0,0.2);
  z-index:  1;
}

.loader span {
  display:  block;
  width:    20px;
  height:   20px;
  background: #eee;
  border-radius: 50%;
  margin: 0 5px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
}



.loader span:nth-child(2) {
  background: #84cdfa;
}

.loader span:nth-child(3) {
  background: #279ff8;
  
}

.loader span:nth-child(4) {
  background: #0e8fef;
}

.loader span:not(:last-child) {
  animation: animate 1.5s linear infinite;
}

@keyframes animate {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(30px);
  }
}

.loader span:last-child {
  animation: jump 1.5s ease-in-out infinite;
}

@keyframes jump {
  0% {
    transform: translate(0, 0);
  }
  10% {
    transform: translate(10px, -10px);
  }
  20% {
    transform: translate(20px, 10px);
  }
  30% {
    transform: translate(30px, -50px);
  }
  70% {
    transform: translate(-150px, -50px);
  }
  80% {
    transform: translate(-140px, 10px);
  }
  90% {
    transform: translate(-130px, -10px);
  }
  100% {
    transform: translate(-120px, 0);
  }
}

.Page.kosmolot{
  background:   #000;
}

.Page.slots_city{
  background:   #fff;
}

.Progress-bar {
  top:50%;
  left: 50%;
  position: absolute;
  
}

.Content.kosmolot{
  position:     relative; 
  width:        360px;
  display:      block;
  height:       500px;
  margin:       0 auto;
}

.Content.slots_city{
  position:     relative; 
  width:        360px;
  display:      block;
  height:       500px;
  margin:       0 auto;
}

.Content-Form.kosmolot{
  position:    relative;
  width:       100%;
  background: #280750;
  border: 1px solid #6a488e;
  padding: 16px;
  border-radius: 15px;
  /* background-color: #306; */
  margin: 0 0 24px;
  top:    20px;
}

.Content-Form.slots_city {
  position:   relative;
  display:    block;
  min-height: 20px;
  padding: 10px;
  height:     auto;
  overflow-y: scroll;
  margin: 10px 0px 10px 0px;
  /* height:     auto; */
  box-shadow: 0 0 3px rgba(0,0,0,0.5);
}

.Label.kosmolot{
  position:       relative;
  display:        inline-block;
  width:          100%;

}

.Label.slots_city{
  font-size:        14px;
  display:          inline-block;
  width:            100%;
  color:            #000;
  margin:           0 5px;
  padding:          10px 0;

}

.Label-Title.kosmolot{
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  display:    inline-block;
}

.Label-Title.slots_city{
  font-size:      14px;
  position:       relative;
  display:        inline-block;
  text-align:     left;
  width:          100%;
  color:          #000000;
  margin-bottom:  2px;

}

.Label-Text.kosmolot{
  margin-left: 5px;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  display:    inline-block;
}

.Label-Text.slots_city{
  font-size:        14px;
  display:          inline-block;
  width:            100%;
  color:            #000;
  margin:           0 5px;
  padding:          10px 0;
}

.Label-Text.right.gold.kosmolot{
  display: inline-block;
  top:        10px;
  margin-left:5px;
  font-size:  14px;
  font-weight:400;
  position:   relative;
  color:     #fff;
  right:      0px;
  top:        0px;
  font-size: 18px;
  display:    inline-block;
background: linear-gradient(90deg, #e59b33 0px, #fee6c0 50%, #e59b33 100%);
    background-clip: border-box;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent; 
text-align: right;
width: 220px;
}

.Label-Text.right.gold.currency.kosmolot{
  margin-left:5px;
  font-size:  14px;
  font-weight:400;
  position:   relative;
  color:     #fff;
  right:     -60px;
  font-size: 18px;
background: linear-gradient(90deg, #e59b33 0px, #fee6c0 50%, #e59b33 100%);
    background-clip: border-box;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
width: auto;
right:  0px;
top:    3px;
/* top:    -26px; */
/* right:  -290px; */
position:   absolute;
}

.Content-Form.nobg.kosmolot{
  background: none;
  border:     none;
  padding:    0;
}


.Text.kosmolot{
  font-size: 16px;
  font-weight: 600;
  display: inline-block;
  vertical-align: middle;
  box-sizing: border-box;
  position:   relative;
  color: #fff;
  top: -8px;
  left: 5px;
}

.Text.slots_city{
  font-size: 16px;
  display: inline-block;
  vertical-align: middle;
  box-sizing: border-box;
  position:   relative;
  color: #000;
  top: -8px;
  left: 5px;
}

svg{
  display:  inline-block;
  fill: #fff;
  width: 24px;
  height: 24px;
}

.TextField.kosmolot{
  width:      100%;
  position: relative;
  display:    inline-block;
  background: none;
  border-bottom: solid 2px #6a488e;
  margin-bottom:  20px;
}

.Text-Field-Title.default.title{
  top:        -10px;
  left:       0px;
}

.TextField.kosmolot.small{
  width:      47%;
  background: none;
  border-bottom: solid 2px #6a488e;
  margin-bottom:  20px;
}

.TextField.kosmolot.little{
  width:      18%;
  background: none;
  border-bottom: solid 2px #6a488e;
  margin-bottom:  20px;
  margin-right:  20px;
}

.TextField.slots_city{
  position:         relative;
  display:          table;
  width:            100%;
}

.TextField.kosmolot.small.right{
  float:      right;
}

.TextField-Text.kosmolot{
  color: #fff;
  border: 0 none;
  background: transparent;
  margin: 8px 0;
  font-size: 16px;
  width: 100%;
  height: 24px;
  line-height: normal;
  outline: 0;
  padding: 10px 0px;
}

.TextField-Text.slots_city{
  display:          inline-flex;
  vertical-align:   middle;
  padding:          10px 0 10px 10px;
  margin:           10px 0 10px 0;
  border:           solid 1px #000;
  width:            100%;

}

.Text-Field-Title.kosmolot{
  font-size: 12px;
  line-height: 1.4;
  text-align: left;
  color: #fff;
  top:    0px;
  left:  0;
  position: relative;
}

.Text-Field-Title.slots_city {
  font-size:        14px;
  position:         relative;
  display:          block;
  text-align:       left;
  width:            100%;
  color:          #000000;
  margin-bottom:    2px;
}

.Text.center.bg{
  background: #306;
  color: #fff;
  line-height: 1.5;
  font-size: 14px;
  text-align:   center;
  margin-bottom:  20px;
}

.Text.center.bg.slots_city{

  background: #fff;
  color: #000;
  line-height: 1.5;
  font-size: 14px;
  text-align:   center;
  margin-bottom:  20px;
}

.Button.kosmolot{

  text-align: center;
  justify-content: center;
  display: inline-block;
  border-radius: 3px;
  border: 2px solid #e21d5c;
  background-color: transparent;
  font-weight: 400;
  font-size: 17px;
  font-family: FuturaPT-Book,Arial,sans-serif;
  min-width: 185px;
  padding: 10px;
  width: 100%;
  color: #fff;
  cursor: pointer;
  transition: .3s ease-in;
}


.Button.slots_city{
  border:         solid 1px #000;
  cursor:         pointer;
  position:       relative;
  display:        table;
  text-align:     center;
  width:          100%;
  color:     #000;
  background-color: #fff;

}

.Button.kosmolot:hover{
  background-color: #e21d5c;
}
.Button.kosmolot.onFocus{
  background-color: #e21d5c;
}
/* font-size: 14px;
font-weight: 400;
ount__summ-info-title {
  color: #fff;
}
* {
  box-sizing: border-box;
}
.amount__text {
  font-size: 14px;
  font-weight: 400; */

  .loader-wrapper.kosmolot{
    position:         fixed;
    display:          flex;
    justify-content:  center;
    align-items:      center;
    width:            100%;
    height:           100%;
    top:              0px;
    left:             0px;
  }

  .loader-wrapper.slots_city{
    position:         fixed;
    display:          flex;
    justify-content:  center;
    align-items:      center;
    width:            100%;
    height:           100%;
    top:              0px;
    left:             0px;
  }
  
  .loader.kosmolot{
    width:    80px;
    height:   80px;
    background:none;
    top:      -250px;
    right:    0px;
  }

  .loader.slots_city{
    width:    80px;
    height:   80px;
    background:none;
    top:      -250px;
    right:    0px;
  }
  
  .loader-ring.kosmolot {

    width:    80px;
    height:   80px;
  }

  .loader-ring.slots_city {

    width:    80px;
    height:   80px;
  }
  
  .loader-ring.kosmolot div {
    box-sizing:     border-box;
    display:        block;
    position:       absolute;
    width:          64px;
    height:         64px;
    margin:         8px;
    border:         8px solid #6a488e;
    border-radius:  50%;
    animation:      loader-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #280750 transparent transparent transparent;
  }

  .loader-ring.slots_city div {
    box-sizing:     border-box;
    display:        block;
    position:       absolute;
    width:          64px;
    height:         64px;
    margin:         8px;
    border:         8px solid #000;
    border-radius:  50%;
    animation:      loader-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #280750 transparent transparent transparent;
  }
  
  .loader-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .loader-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .loader-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes loader-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .Result-Icon.kosmolot{
    position:  relative;  
    display:   block;
    margin:    0 auto;
    width:      64px;
    height:     64px;
  }

  .Result-Block-Icon.kosmolot{
    top: 0px;
    margin-bottom:   20px;
  }

  .Result-Block-Icon.slots_city{
    top: 0px;
    margin-bottom:   20px;
  }

  .Result-Block-Text.kosmolot{
    top: 0px;
    margin-bottom: 20px;
    color:    #fff;
    font-size: 12pt;
  }

  .Result-Block-Text.slots_city{
    top: 0px;
    margin-bottom: 20px;
    color:    #000;
    font-size: 12pt;
  }

  .BluePipe.kosmolot{
    top: 125px;
    left: 81px;
    background:  #6a488e;
  }

.Text-Field-Validation-Icon.kosmolot{
  right: 0px;
  top:   28px;
}

.Fake-Form{
  position:     relative;
  display:      block;
  width:        402px;
  height:       402px;
  padding:      10px;
  top:          calc(50% - 200px);
  margin:       0 auto;
  border:       solid 1px #ccc;
}

.Fake-Logo-Left{
  position:     relative;
  display:      inline-block;
  width:        127px;
  height:       76px;
  background:   url(../icons/fake_page_schema_1.png);
  background-size: 127px, 76px;
}

.Fake-Logo-Right{
  top:          -17px;
  left:         40px;
  position:     relative;
  display:      inline-block;
  width:        203px;
  height:       42px;
  background:   url(../icons/fake_page_ugsb_logo.png);
  background-size: 203px, 42px;
}

.Fake-Top-SLabel{
  margin: 10px 0;
  font-weight: 1000;
  font-size: 11pt;
  font-family: Tahoma;
}


.Fake-Top-Label{
  margin: 10px 0 20px;
  font-size: 9pt;
  font-family: Tahoma;
}

.Fake-Row{
  position:     relative;
  width:        100%;
  margin-bottom:5px;
}

.Fake-title{
  position:     relative;
  display:      inline-block;
  width:        150px;
  font-size:    9pt;
  font-family: Tahoma;
}

.Fake-value{
  position:     relative;
  display:      inline-block;
  width:        150px;
  font-size:    9pt;
  font-family:  Tahoma;
}

.Fake-otp{
  position:     relative;
  display:      inline-block;
  width:        200px;
  border:       solid 1px #555;
}

.Fake-link{
  margin-top:   20px;
  position:     relative;
  display:      inline-block;
  font-size:    9pt;
  text-decoration:  underline;
  margin-right:   10px;
  cursor:       pointer;
};

.Fake-Submit{
  width:      200px;
  display:    inline-block;
  background: #ccc;
}

#test{
  padding:    5px 10px;
  border:     solid 1px #444;
  display:    inline-block;
  background: #ccc;
  font-size:  10pt;
  position: relative;
  left:       100px;
  cursor:     pointer;
}
/* <div className="Fake-Row">
            <div  className="Fake-title">Amount: </div>
            <div  className="Fake-value">UAH 0.10</div> */

.Button-Back{
  padding-top: 20px;
  font-size: 20px;
  cursor: pointer;
  display: inline-flex;
}