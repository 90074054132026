.loader-ring.default {
  width:    80px;
  height:   80px;
}

.loader-ring.default div {
  box-sizing:     border-box;
  display:        block;
  position:       absolute;
  width:          64px;
  height:         64px;
  margin:         8px;
  border:         8px solid #64BA5E;
  border-radius:  50%;
  animation:      loader-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #64BA5E transparent transparent transparent;
}


.loader.default{
  width:    80px;
  height:   80px;
  background:none;
  box-shadow: none;
  top:      -250px;
  right:    40px;
}

.loader-wrapper.default{
  position:         fixed;
  display:          flex;
  justify-content:  center;
  align-items:      center;
  width:            100%;
  height:           100%;
  top:              0px;
  left:             0px;
}

.loader-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.loader-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.loader-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes loader-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}